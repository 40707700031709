/*
 * jQuery FlexSlider v2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */

 
/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.img-slider a:active,
.flexslider-app a:active,
.flexslider-thumbnails:active, .flexslider-photo a:active,
.flex-container a:focus,
.flexslider a:focus,
.img-slider a:focus,
.flexslider-thumbnails:focus, .flexslider-photo a:focus  {outline: none;}
.slides,
.flex-control-nav,
.flex-direction-nav {margin: 0; padding: 0; list-style: none;} 

/* FlexSlider Necessary Styles
*********************************/ 
.flexslider, .flexslider-thumbnails, .flexslider-photo, .img-slider, .flexslider-app  {margin: 0; padding: 0;}
.img-slider {position:relative;}
.flexslider .slides > li, .flexslider-thumbnails > li, .flexslider-photo .slides > li, .img-slider .slides > li, .flexslider-app .slides > li  {display: none; -webkit-backface-visibility: hidden;margin:0 0 -5px 0; padding: 0; list-style:none;} /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img, .flexslider-thumbnails, .flexslider-photo .slides img, .img-slider .slides img, .flexslider-app .slides img  {width: 100%; display: block;}
.flex-pauseplay span {text-transform: capitalize;}

/* Clearfix for the .slides element */
.slides:after {content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;} 
html[xmlns] .slides {display: block;} 
* html .slides {height: 1%;}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {display: block;}


/* FlexSlider Default Theme
*********************************/
.flexslider, .flexslider-thumbnails, .flexslider-photo, .img-slider, .flexslider-app {margin:0;zoom: 1;}
.img-slider {margin:0 0 -5px;}
.flex-viewport {max-height: 2000px; -webkit-transition: all 1s ease; -moz-transition: all 1s ease; transition: all 1s ease;}
.loading .flex-viewport {max-height: 300px;}
.flexslider .slides, .flexslider-thumbnails .slides, .flexslider-photo .slides, .img-slider .slides, .flexslider-app .slides {zoom: 1;margin:0;
padding:0;}

.carousel li {margin-right: 5px}


/* Direction Nav */
.flex-direction-nav a, .flex-direction-nav a:focus, .flex-direction-nav a:active {text-decoration:none;overflow: hidden; margin:0;padding:0;border-radius:2px; display: block; background:none;position: absolute; bottom:-40px; cursor: pointer; opacity: 1; -webkit-transition: all .3s ease;text-align:center;color:#656565;font-size:64px;line-height:64px;}

.flex-direction-nav .flex-next {right: -125px; display: block;}
.flex-direction-nav .flex-next:before {font-family: 'FontAwesome'; content: "\f105"; }
.flex-direction-nav .flex-prev {right: -95px;}
.flex-direction-nav .flex-prev:before {font-family: 'FontAwesome'; content: "\f104"; }
.flexslider:hover .flex-next, .flexslider-photo:hover .flex-next {opacity: 1;}
.flexslider:hover .flex-prev, .flexslider-photo:hover .flex-prev {opacity: 1;}
.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover, .flexslider-photo:hover .flex-next:hover, .flexslider-photo:hover .flex-prev:hover {opacity: 1;text-decoration:none;}
.flex-direction-nav .disabled {opacity: .3!important; filter:alpha(opacity=30); cursor: default;}

/* Custome Direction Nav */
.img-slider .flex-direction-nav a, .img-slider .flex-direction-nav a:focus, .img-slider .flex-direction-nav a:active,
.flexslider-thumbnails .flex-direction-nav a, .img-slider .flex-direction-nav a:focus, .img-slider .flex-direction-nav a:active,
.flexslider-photo .flex-direction-nav a, .img-slider .flex-direction-nav a:focus, .img-slider .flex-direction-nav a:active{
	width:40px;
	height:40px;
	background:rgba(0, 0, 0, 0.5);
	bottom:auto;
	top:50%;
	margin-top:-20px;
	font-size:24px;
	line-height:40px;
	opacity: 0;
	filter: Alpha(Opacity=0);/*IE7 fix*/
}

.flexslider-thumbnails .flex-direction-nav a, .flexslider-thumbnails .flex-direction-nav a:focus, .flexslider-thumbnails .flex-direction-nav a:active,
.flexslider-photo .flex-direction-nav a, .flexslider-photo .flex-direction-nav a:focus, .flexslider-photo .flex-direction-nav a:active {
	color:#fff;
	line-height:36px;
}

.img-slider .flex-direction-nav .flex-next,
.flexslider-thumbnails .flex-direction-nav .flex-next,
.flexslider-photo .flex-direction-nav .flex-next{
	right: 0;
	border-radius:2px 0 0 2px;
}

.img-slider .flex-direction-nav .flex-prev,
.flexslider-thumbnails .flex-direction-nav .flex-prev,
.flexslider-photo .flex-direction-nav .flex-prev{
	left: 0;
	border-radius:0 2px 2px 0;
}

.img-slider:hover .flex-direction-nav a, .img-slider .flex-direction-nav a:focus, .img-slider .flex-direction-nav a:active,
.flexslider-thumbnails:hover .flex-direction-nav a, .img-slider .flex-direction-nav a:focus, .img-slider .flex-direction-nav a:active,
.flexslider-photo:hover .flex-direction-nav a, .img-slider .flex-direction-nav a:focus, .img-slider .flex-direction-nav a:active{
	opacity: 1;
	filter: Alpha(Opacity=100);/*IE7 fix*/
}

.img-slider .flex-direction-nav .flex-next:hover,
.img-slider .flex-direction-nav .flex-prev:hover,
.flexslider-thumbnails .flex-direction-nav .flex-next:hover,
.flexslider-thumbnails .flex-direction-nav .flex-prev:hover,
.flexslider-photo .flex-direction-nav .flex-next:hover,
.flexslider-photo .flex-direction-nav .flex-prev:hover {
	color:#fff;
}

/* Control Nav */
.flex-control-nav {z-index:4;width: 100%; position: absolute; left:0;bottom:10px; text-align: center;margin:0;padding:0;}
.img-slider .flex-control-nav {width: auto;left:auto;right:20px;bottom:10px;}
.flexslider-app .flex-control-nav{bottom:4px;margin-bottom:-40px;}
.flex-control-nav li {margin: 5px 0 0 0;padding:0; display: inline-block; zoom: 1; *display: inline;}
.img-slider .flex-control-nav li {display: block;}
.flex-control-paging li a {width: 12px; height: 12px;display: block; background: none; border:2px solid #656565;cursor: pointer; text-indent: -9999px;border-radius:50%;}
.flex-control-paging li a:hover { background: none; }
.flex-control-paging li a.flex-active { background: none;cursor: default; }

.flex-control-thumbs {margin: 0 0 0; position: static; overflow: hidden;}
.flex-control-thumbs li {width: 25%; float: left; margin: 0;padding:0 2px 0 3px;}
.flex-control-thumbs img {width: 100%; display: block;padding:5px; cursor: pointer; border:1px solid #e5e5e5;}
.flex-control-thumbs img:hover {opacity: 1;}
.flex-control-thumbs .flex-active {opacity: 1; cursor: default;}
